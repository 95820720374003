<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar video" : "Cadastrar curso" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-file-input
                label="Capa do curso"
                v-model="data.file"
                :error-messages="errors.file"
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="12">
        <v-file-input
          label="Arquivo PDF"
          v-model="data.arquivos"
          :error-messages="errors.arquivos"
        ></v-file-input>
      </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Titulo*"
                :disabled="enabledInputs"
                v-model="data.titulo"
                :error-messages="errors.titulo"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                prepend-icon=""
                :autofocus="true"
                label="Descrição do Curso"
                :disabled="enabledInputs"
                v-model="data.descricao"
                :error-messages="errors.descricao"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                v-model="data.private"
                label="Privado"
                color="green"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import instanceAxios from "../../plugins/axios";

export default {
  name: "UserForm",

  mounted: async function() {
    const id = this.$route.params.id;
    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }
  },

  components: {},

  data: () => ({
    data: {},
    errors: {},
    listRoles: [],
    loading: false,
    enabledInputs: false,
    ComercialService: new ComercialService(""),
    status: ["Cliente Fechado", "Proposta Enviada", "Agendar Contato"],
  }),

  methods: {
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;
        const id = this.data.id !== undefined ? "/" + this.data.id : "";

        let formData = new FormData();

        formData.append("titulo", this.data.titulo);
        formData.append("descricao", this.data.descricao);
        formData.append("private", this.data.private);
        
        if (this.data.file) {
          formData.append("file", this.data.file);
        }
        if (this.data.arquivos) {
          formData.append("arquivos", this.data.arquivos);
        }

        if (this.$route.params.id) {
          await instanceAxios.post("cursos/update" + id, formData);
        } else {
          await instanceAxios.post("cursos" + id, formData);
        }

        ToastService("Ação realizada com sucesso.", "success");
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    getDate(payload) {
      this.data.data_retorno = payload.date;
    },

    async showData(id) {
      try {
        this.data = await this.ComercialService.show("cursos/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.back();
    },
  },
};
</script>
